import React from 'react';
import { graphql, ChildDataProps } from 'react-apollo';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import {
  getCollection,
  GetCollectionResponse,
  GetCollectionVariables
} from '../graphql/getCollection';
import { App } from '../types';
import { getCategory } from '../graphql/getCategory';
import CardList from '../components/CardList';
import AppTileCard from '../components/AppTileCard';
import AllCallout from '../components/AllCallout';
import { Text, Section } from '../components/basic-components';
import ClockTileCard from '../components/ClockTileCard';
import { LoadingPage } from '../components/LoadingPage';
import { ActiveNavItem } from '../components/Header';
import PagingNavigation from '../components/PagingNavigation';
import PremiumNotice from '../components/PremiumNotice';
import { ErrorPage } from './ErrorPage';
import { PageNotFound } from './404';
import { allCategoriesIds } from '../sharedConfig';
import { useI18n } from '../hooks';
import { getPreviewImage } from '../utils';
import { below } from '../media-query-utils';

export type CollectionProps = {
  id: string;
  pageKey?: string;
  pathname: string;
  clientIpAddress?: string;
};

type ChildProps = ChildDataProps<
  CollectionProps,
  GetCollectionResponse,
  GetCollectionVariables
>;

const CollectionSection = styled(Section)`
  padding-top: 60px;
  padding-bottom: 60px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;

  ${below('mobile')} {
    flex-direction: column-reverse;
  }
`;

const Title = styled(Text)`
  align-self: flex-start;
  flex-grow: 1;

  font-size: 30px;
  font-weight: 600;
`;

const Collection: React.FC<ChildProps> = (props) => {
  const { data, pathname, clientIpAddress } = props;
  const { collection, loading, error } = data;
  const { t } = useI18n();

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!collection || !collection.pagedApps) {
    return <PageNotFound />;
  }

  if (collection.pagedApps.apps.length === 0) {
    return (
      <ErrorPage>{t`This collection is not available at the moment.`}</ErrorPage>
    );
  }

  const { appType, id, name, pagedApps } = collection;

  const isApp = appType === 'APP';
  const activeNavItem = isApp ? 'apps' : 'clocks';

  return (
    <React.Fragment>
      <CollectionSection>
        <Helmet>
          <title>
            {collection.name.includes('Primary') ? null : collection.name}
          </title>
        </Helmet>
        <ActiveNavItem item={activeNavItem} />
        <Header>
          <Title>{collection.name.includes('Primary') ? null : name}</Title>
          {!isApp && <PremiumNotice />}
        </Header>
        <CardList>
          {pagedApps.apps.map((app: App) =>
            app.type === 'APP' ? (
              <AppTileCard
                key={app.id}
                img={getPreviewImage(app)}
                name={app.name}
                author={app.developer.name}
                id={app.id}
                isPremium={app.isPremium}
              />
            ) : (
              <ClockTileCard
                key={app.id}
                img={getPreviewImage(app)}
                name={app.name}
                author={app.developer.name}
                id={app.id}
                isPremium={app.isPremium}
              />
            )
          )}
        </CardList>
        <PagingNavigation pagedApps={pagedApps} pathname={pathname} />
      </CollectionSection>
      {!allCategoriesIds.includes(id) && (
        <AllCallout
          isApp={isApp}
          clientIpAddress={clientIpAddress}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

const createPage = (query: any) =>
  graphql<CollectionProps, Response, GetCollectionVariables, ChildProps>(
    query,
    {
      options: ({ id, pageKey, clientIpAddress }) => ({
        variables: {
          id,
          pageKey,
          clientIpAddress
        }
      })
    }
  )(Collection);

export const CollectionPage = createPage(getCollection);
export const CategoryPage = createPage(getCategory);
