import gql from 'graphql-tag';
import { Collection } from '../types';
import { appsPageSize, numNextPages, numPrevPages } from '../sharedConfig';
import { pagedApps } from './fragments';

export type GetCollectionResponse = {
  collection: Collection;
};

export type GetCollectionVariables = {
  id: string;
  pageKey?: string;
  clientIpAddress?: string;
};

export const getCollection = gql`
  query collection($id: ID!, $pageKey: ID, $clientIpAddress: String) {
    collection(id: $id, clientIpAddress: $clientIpAddress) {
      id
      name
      pagedApps(pageOptions: {
        pageKey: $pageKey,
        limit: ${appsPageSize},
        numNextPages: ${numNextPages},
        numPrevPages: ${numPrevPages}
      }) {
        ...pagedApps
      }
      appType
      flags
    }
  }
  ${pagedApps}
`;
