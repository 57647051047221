import React, { useMemo, Fragment } from 'react';
import styled, { css } from 'styled-components';
import queryString from 'query-string';
import Helmet from 'react-helmet';

import leftIcon from '../images/icons/left.svg';
import greyLeftIcon from '../images/icons/left-grey.svg';
import rightIcon from '../images/icons/right.svg';
import greyRightIcon from '../images/icons/right-grey.svg';
import { PagedApps } from '../types';
import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import { appsPageSize, numVisiblePages } from '../sharedConfig';
import { BaseLink, smallButtonStyle } from './basic-components';
import { useI18n } from '../hooks';

type PagingNavigationProps = {
  pagedApps: PagedApps;
  pathname: string;
};

type PageNumberProps = {
  disabled?: boolean;
};

const pageNumberStyle = css`
  ${smallButtonStyle}
  font-size: 16px;
  font-weight: 600;
  border: solid 1px ${colors.linkWater};
  margin-left: 10px;
  user-select: none;
`;

const PageNumberLink = styled(BaseLink)`
  ${pageNumberStyle}
  color: ${colors.irisBlue};
  background-color: ${colors.white};
`;

const PageNumberText = styled.div<PageNumberProps>`
  ${pageNumberStyle}
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${colors.linkWater};
          border-color: ${colors.lightWhisper};
          cursor: default;
        `
      : css`
          color: ${colors.tangaroa};
        `};
`;

const PageNumbersContainer = styled.div`
  display: flex;
  margin-top: 19px;

  justify-content: flex-end;

  ${below('mobile')} {
    justify-content: center;
    margin-left: -10px;
  }

  // This is to hide the arrow buttons on small mobile screens
  // so they don't go outside the screen
  ${below('smallMobile')} {
    & > div:first-child,
    & > a:first-child,
    & > div:last-child,
    & > a:last-child {
      visibility: hidden;
    }
  }
`;

const LeftIcon = styled.img.attrs(({ disabled }: PageNumberProps) => ({
  src: disabled ? greyLeftIcon : leftIcon
}))<PageNumberProps>`
  height: 18px;
  width: 9px;
`;

const RightIcon = styled(LeftIcon).attrs(({ disabled }: PageNumberProps) => ({
  src: disabled ? greyRightIcon : rightIcon
}))<PageNumberProps>``;

const createPageUrl = (pathname: string, pageKey?: string) =>
  `${pathname}${pageKey ? `?${queryString.stringify({ pageKey })}` : ''}`;

const pageNumToComponentMap: { [key: string]: any } = {
  '<': LeftIcon,
  '>': RightIcon
};
const createPageLink = (to: string, pageNum: string | number) => {
  const PageNum = pageNumToComponentMap[pageNum];
  return (
    <PageNumberLink to={to} key={pageNum} direct>
      {PageNum ? <PageNum /> : pageNum}
    </PageNumberLink>
  );
};

const PagingNavigation: React.FC<PagingNavigationProps> = ({
  pagedApps,
  pathname
}) => {
  const { t } = useI18n();
  const { offset, nextPages } = pagedApps;
  const prevPages = pagedApps.prevPages;

  // If there are no more pages, do nothing
  if (
    (offset === 0 && !nextPages.length) ||
    (offset > 0 && !prevPages.length)
  ) {
    return null;
  }

  const totalKnownPages = prevPages.length + nextPages.length + 1;
  const currentPageIndex = Math.ceil(offset / appsPageSize);

  const centerPos = Math.ceil(numVisiblePages / 2) - 1;

  // Which of the shown pages is highlighted.  Usually the center, but we need
  // to account for the first and last few pages.
  const selectedPosition =
    prevPages.length <= nextPages.length
      ? Math.min(prevPages.length, centerPos)
      : Math.max(numVisiblePages - nextPages.length - 1, centerPos);

  const prevItems = useMemo(() => {
    const items = [];
    const end = Math.min(selectedPosition, prevPages.length);
    for (let i = 0; i < end; i += 1) {
      items.push(
        createPageLink(
          createPageUrl(pathname, prevPages[i]),
          currentPageIndex - i
        )
      );
    }

    items.push(
      prevPages.length ||
        (numVisiblePages < totalKnownPages && currentPageIndex > centerPos) ? (
        createPageLink(createPageUrl(pathname, prevPages[0]), '<')
      ) : (
        <PageNumberText key="left" disabled>
          <LeftIcon disabled />
        </PageNumberText>
      )
    );

    return items.reverse();
  }, [selectedPosition, pathname, pagedApps.prevPages, currentPageIndex]);

  const nextItems = useMemo(() => {
    const items = [];
    const end = Math.min(
      numVisiblePages - selectedPosition - 1,
      nextPages.length
    );
    for (let i = 0; i < end; i += 1) {
      items.push(
        createPageLink(
          createPageUrl(pathname, nextPages[i]),
          currentPageIndex + i + 2
        )
      );
    }

    items.push(
      nextPages.length ||
        (numVisiblePages < totalKnownPages && nextPages.length > centerPos) ? (
        createPageLink(createPageUrl(pathname, nextPages[0]), '>')
      ) : (
        <PageNumberText key="right" disabled>
          <RightIcon disabled />
        </PageNumberText>
      )
    );

    return items;
  }, [
    selectedPosition,
    pathname,
    nextPages,
    currentPageIndex,
    numVisiblePages,
    prevItems
  ]);

  const pageNumber = currentPageIndex + 1;
  // This is done this way for the translators instead of seeing %s
  const pageName = '%s';
  const title = t`Page ${pageNumber} of ${pageName} | Fitbit App Gallery`;

  return (
    <Fragment>
      {pageNumber !== 1 && <Helmet titleTemplate={title} />}
      <PageNumbersContainer>
        {prevItems}
        <PageNumberText>{pageNumber}</PageNumberText>
        {nextItems}
      </PageNumbersContainer>
    </Fragment>
  );
};

export default PagingNavigation;
